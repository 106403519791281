import { Check, Copy } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RxHamburgerMenu } from "react-icons/rx";

interface ImageProps {
  src: string;
  alt: string;
  className?: string;
}

const Image: React.FC<ImageProps> = ({ src, alt, className }) => (
  <img loading="lazy" src={src} alt={alt} className={className} />
);

interface NavItemProps {
  children: React.ReactNode;
}

const NavItem: React.FC<NavItemProps> = ({ children }) => (
  <div className="flex gap-2.5 justify-center p-2.5">{children}</div>
);

const Header: React.FC = () => {
  const [copy, setCopy] = React.useState<Record<string, boolean>>();
  const [idTimeout, setIdTimeout] = React.useState<any>();
  const [direction, setDirection] = React.useState("ltr");
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    console.log("toggleclicked");
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = (path?: string) => {
    setIsMenuOpen(false);
    if (path) navigate(path);
  };

  const handleCopy = (val: string, type: string) => {
    navigator.clipboard.writeText(val);
    if (type === "email") setCopy({ ...copy, email: true });
    if (type === "phone") setCopy({ ...copy, phone: true });
    const timeoutId = setTimeout(() => {
      setCopy({ email: false, phone: false });
      clearTimeout(idTimeout);
    }, 1000);
    setIdTimeout(timeoutId);
  };

  const { t, i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    setDirection(newLanguage);
    i18n.changeLanguage(newLanguage);
    console.log(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  return (
    <nav className="flex gap-5  md:py-4 md:px-24 justify-between text-sm font-medium text-center text-white max-md:flex-wrap bg-black">
      <div onClick={() => (window.location.href = "/")}>
        <Image
          src="/assets/images/logo.png"
          alt="Placeholder image"
          className="shrink-0 max-w-full aspect-[3.85] w-[244px] cursor-pointer "
        />
      </div>
      <div className="flex justify-between items-center px-5 relative">
        <div className="display md:hidden relative" onClick={toggleMenu}>
          <RxHamburgerMenu className="w-[40px] h-[80px]" />
        </div>
      </div>

      {isMenuOpen && (
        <div className="absolute flex flex-col gap-5 items-start mt-[4.8rem] z-10 bg-black rounded ">
         
          <Link
            to="/reservation-status"
            onClick={() => handleMenuItemClick("/reservation-status")}
          >
            <div className="cursor-pointer pl-4 pr-10">
              {t(`header.links.ReservationStatus`)}
            </div>
          </Link>
          
          <a
            href="https://vendor.enqlha.com"
            target="_blank"
            className="flex cursor-pointer"
            onClick={() => handleMenuItemClick()}
          >
            <div className="pl-4  ">{t(`header.links.carOwnerLogin`)}</div>
            <Image
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/3b1c90039587a4dc8b4dc84ff9776be634788afd04343db9160854046dc3b8b7?apiKey=8d89067347d641b99b038352d46b6e1d&"
              alt="Arrow icon"
              className="shrink-0 my-auto aspect-[0.81] fill-white w-[13px]  "
            />
          </a>
        
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="flex">
                <div className="my-auto pl-4">
                  {t(`header.links.contactUs`)}
                </div>
                <Image
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a16b2deebdd0cb6bb88a8b4342bbcf78d80bbb81067fde70048b5ffcc47604d?apiKey=8d89067347d641b99b038352d46b6e1d&"
                  alt="Contact icon"
                  className="shrink-0 w-6 aspect-square"
                />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-60 bg-gray-200 backdrop-blur-[14px] mt-4">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    handleMenuItemClick();
                  }}
                >
                  <div className="flex flex-col">
                    <span className="font-bold">
                      {t(`header.links.emailLabel`)}
                    </span>
                    <span>info@enqlha.com</span>
                  </div>
                  <DropdownMenuShortcut
                    onClick={() => handleCopy("info@enqlha.com", "email")}
                  >
                    {copy?.email ? (
                      <Check className="w-5 hover:cursor-pointer" />
                    ) : (
                      <Copy className="w-5 hover:cursor-pointer" />
                    )}
                  </DropdownMenuShortcut>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    handleMenuItemClick();
                  }}
                >
                  <div className="flex flex-col">
                    <span className="font-bold">
                      {t(`header.links.phoneLabel`)}
                    </span>
                    <span>+966501920304</span>
                  </div>
                  <DropdownMenuShortcut
                    onClick={() => handleCopy("+966501920304", "phone")}
                  >
                    {copy?.phone ? (
                      <Check className="w-5 hover:cursor-pointer" />
                    ) : (
                      <Copy className="w-5 hover:cursor-pointer" />
                    )}
                  </DropdownMenuShortcut>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
          <div className="cursor-pointer flex pl-4 mb-3 self-stretch ">
            <select
              onChange={toggleLanguage}
              value={i18n.language}
              className="px-4 py-2 rounded text-black cursor-pointer"
            >
              <option value="en">English</option>
              <option value="ar">العربية</option>
            </select>
          </div>
        </div>
      )}

      <div className="hidden md:flex gap-5 justify-between items-center px-5 my-auto max-md:flex-wrap">
        <Link to="/reservation-status">
          <div className="self-stretch my-auto cursor-pointer ">
            {t(`header.links.ReservationStatus`)}
          </div>
        </Link>
        <a
          href="https://vendor.enqlha.com"
          target="_blank"
          className="flex gap-2.5 self-stretch my-auto cursor-pointer"
        >
          <div>{t(`header.links.carOwnerLogin`)}</div>
          <Image
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3b1c90039587a4dc8b4dc84ff9776be634788afd04343db9160854046dc3b8b7?apiKey=8d89067347d641b99b038352d46b6e1d&"
            alt="Arrow icon"
            className="shrink-0 my-auto aspect-[0.81] fill-white w-[13px]"
          />
        </a>

        <div className=" cursor-pointer flex flex-col justify-center self-stretch px-6 py-1.5 border border-white border-solid shadow-lg rounded-[42px] max-md:px-5">
          <NavItem>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className="flex">
                  <div className="my-auto">{t(`header.links.contactUs`)}</div>
                  <Image
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a16b2deebdd0cb6bb88a8b4342bbcf78d80bbb81067fde70048b5ffcc47604d?apiKey=8d89067347d641b99b038352d46b6e1d&"
                    alt="Contact icon"
                    className="shrink-0 w-6 aspect-square"
                  />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-60 bg-gray-200 backdrop-blur-[14px] mt-4">
                <DropdownMenuGroup>
                  <DropdownMenuItem onClick={(e) => e.preventDefault()}>
                    <div className="flex flex-col">
                      <span className="font-bold">
                        {t(`header.links.emailLabel`)}
                      </span>
                      <span>info@enqlha.com</span>
                    </div>
                    <DropdownMenuShortcut
                      onClick={() => {
                        handleCopy("info@enqlha.com", "email");
                      }}
                    >
                      {copy?.email ? (
                        <Check className="w-5 hover:cursor-pointer" />
                      ) : (
                        <Copy className="w-5 hover:cursor-pointer" />
                      )}
                    </DropdownMenuShortcut>
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={(e) => e.preventDefault()}>
                    <div className="flex flex-col">
                      <span className="font-bold">
                        {t(`header.links.phoneLabel`)}
                      </span>
                      <span>+966501920304</span>
                    </div>
                    <DropdownMenuShortcut
                      onClick={() => {
                        handleCopy("+966501920304", "phone");
                      }}
                    >
                      {copy?.phone ? (
                        <Check className="w-5 hover:cursor-pointer" />
                      ) : (
                        <Copy className="w-5 hover:cursor-pointer" />
                      )}
                    </DropdownMenuShortcut>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </NavItem>
        </div>
        <div className="cursor-pointer flex justify-center self-stretch px-6 py-1.5 max-md:px-5">
          <select
            onChange={toggleLanguage}
            value={i18n.language}
            className="px-4 py-2 rounded text-black cursor-pointer"
          >
            <option value="en">English</option>
            <option value="ar">العربية</option>
          </select>
        </div>
      </div>
    </nav>
  );
};

export default Header;
