import React, { useState, useEffect } from "react";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";

import axios from "axios";
import Loader from "../../utils/Loader";
import { backendUrl } from "../../constants/backendUrl";
import { toastMessage } from "../..//utils/toast";
import { ChevronDown } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { calculateDiscountedPrice, calculateTotalPrice } from "../../utils/utility";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import { updateSelection } from "../..//store/slices/selected";
import { useTranslation } from "react-i18next";
import moment from "moment";
const SearchPage = () => {
  const [carType, setCarType] = useState("");
  const [carTypes, setCarTypes] = useState([] as any);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [minPrice, setMinPrice] = useState("" as any);
  const [maxPrice, setMaxPrice] = useState("" as any);
  const [priceSearch, setPriceSearch] = useState(false);
  const [cars, setCars] = useState([] as any);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const dispatch = useAppDispatch();
  const { pickUpDate, pickUpLocation, dropOffLocation, dropOffDate, type } =
    useAppSelector((state) => state.selected);
  // let [searchParams, setSearchParams] = useSearchParams();

  const getCarTypes = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${backendUrl}/app/car/getUniqueCarTypes`
      );
      console.log(data.data);
      setCarTypes(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage("Something went wrong in fetching car types", "error");
    }
  };

  useEffect(() => {
    getCarTypes();
  }, []);

  const getCars = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${backendUrl}/app/car/searchCars?pickUpLocation=${pickUpLocation}&dropOffLocation=${dropOffLocation}&from=${pickUpDate}&to=${dropOffDate}&page=${page}&carType=${carType}&minPrice=${minPrice}&maxPrice=${maxPrice}&type=${type}`
      );
      console.log(data);
      setCars(data.data);
      setTotalCount(data.totalCount);
      setTotalPage(data.totalPage);
      setPage(data.page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage("Something went wrong in fetching cars", "error");
    }
  };

  useEffect(() => {
    getCars();
  }, [
    pickUpDate,
    pickUpLocation,
    dropOffDate,
    pickUpDate,
    page,
    carType,
    priceSearch,
  ]);

  const { t } = useTranslation();

  return (
    <>
      <Loader loading={loading} />
      <div className="w-full flex flex-col gap-8 px-5 md:px-24 py-8">
        <div className="w-full md:grid md:grid-cols-11  flex flex-col gap-8">
          <div
            onClick={() => {
              navigate("/");
            }}
            className=" cursor-pointer flex col-span-5 flex-col p-6 text-base font-semibold bg-white rounded-md shadow-lg w-full text-zinc-800"
          >
            <div className="flex gap-3 items-center">
              <div className="justify-center flex items-center self-stretch p-2.5 w-8 h-8 whitespace-nowrap rounded bg-stone-300">
                1
              </div>
              <div className="flex-1 self-stretch my-auto">
                {t(`search.rentalLocation`)}
              </div>
              <img
                loading="lazy"
                src="/assets/icons/edit.svg"
                className="shrink-0 self-stretch my-auto aspect-square w-[25px]"
              />
            </div>
            <div className="flex gap-1.5 mt-2.5">
              <div className="flex-1">{t(`search.pick up`)}</div>
              <div className="flex-1">{t(`search.return`)}</div>
            </div>
            <div className="flex gap-1.5 text-sm font-medium">
              <div className="flex-1">{pickUpLocation}</div>
              <div className="flex-1">{dropOffLocation}</div>
            </div>
            <div className="flex gap-1.5 text-sm font-medium">
              <div className="flex-1">{pickUpDate}</div>
              <div className="flex-1">{dropOffDate}</div>
            </div>
          </div>
          <div className="flex col-span-3 flex-col p-6 text-sm font-medium bg-white rounded-md border border-gray-500 border-solid shadow-lg w-full text-zinc-800">
            <div className="flex gap-3 items-center text-base font-semibold">
              <div className="justify-center flex items-center self-stretch p-2.5 w-8 h-8 whitespace-nowrap rounded bg-stone-300">
                2
              </div>
              <div className="flex-1 self-stretch my-auto">
                {t(`search.selectVehicle`)}
              </div>
              <img
                loading="lazy"
                src="/assets/icons/edit.svg"
                className="shrink-0 self-stretch my-auto aspect-square w-[25px]"
              />
            </div>
            {/* <div className="mt-2.5">Suzuki Alto 0.8</div>
            <div>SAR 519.80</div> */}
          </div>
          <div className="flex col-span-3 flex-col p-6 text-sm font-medium bg-white rounded-md   shadow-lg w-full text-zinc-800">
            <div className="flex gap-3 items-center text-base font-semibold">
              <div className="justify-center flex items-center self-stretch p-2.5 w-8 h-8 whitespace-nowrap rounded bg-stone-300">
                3
              </div>
              <div className="flex-1 self-stretch my-auto">
                {t(`search.reviewAndSubmit`)}
              </div>
              <img
                loading="lazy"
                src="/assets/icons/edit.svg"
                className="shrink-0 self-stretch my-auto aspect-square w-[25px]"
              />
            </div>
            {/* <div className="mt-2.5 font-bold ">Total Price</div>
            <div>SAR 519.80</div> */}
          </div>
        </div>
        <div className="w-full flex flex-col  gap-4 md:gap-6">
          <h6 className="text-[#7F778F] text-3xl font-bold">
            {t(`search.choose your Car`)}
          </h6>
          <div className="w-full flex md:justify-between items-center">
            <div className="text-black text-sm md:text-base font-medium">
              {totalCount} {t("search.available")}
            </div>

            <div className="flex  md:gap-16 items-center">
              <Select
                value={carType}
                onValueChange={(value) => {
                  setCarType(value);
                  setPage(1);
                }}
              >
                <SelectTrigger className="w-fit gap-2 poppins focus:border-none focus:outline-none focus:ring-0 outline-none border-none  ">
                  <SelectValue placeholder={t("search.Car Type")} />
                </SelectTrigger>
                <SelectContent>
                  {carTypes.map((carType: any) => (
                    <SelectItem key={carType.id} value={carType?.carType}>
                      {carType?.carType}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Popover>
                <PopoverTrigger>
                  <div className="flex items-center gap-2 w-fit">
                    <p className="poppins text-sm">{t(`search.Price Range`)}</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="9"
                      viewBox="0 0 14 9"
                      fill="none"
                    >
                      <path
                        d="M5.98091 8.05305L0.796908 2.86705C0.547943 2.61655 0.408203 2.27772 0.408203 1.92455C0.408203 1.57137 0.547943 1.23254 0.796908 0.982046C1.04753 0.733323 1.38631 0.59375 1.73941 0.59375C2.0925 0.59375 2.43128 0.733323 2.68191 0.982046L6.92491 5.22405L11.1669 0.983047C11.4177 0.734261 11.7566 0.594663 12.1099 0.594663C12.4632 0.594663 12.8021 0.734261 13.0529 0.983047C13.3017 1.23384 13.4413 1.57279 13.4413 1.92605C13.4413 2.27931 13.3017 2.61825 13.0529 2.86905L7.86691 8.05405C7.61637 8.30332 7.27733 8.44325 6.92391 8.44325C6.57049 8.44325 6.23145 8.30332 5.98091 8.05405"
                        fill="#333333"
                      />
                    </svg>
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <div className="w-full flex flex-col gap-2">
                    <div className="w-full grid grid-cols-2 gap-4">
                      <div className="flex flex-col items-start py-3.5 pr-6 pl-4 text-xs text-black bg-white rounded-md border border-gray-500 border-solid shadow-lg w-full">
                        <div>{t("search.minPrice")}</div>
                        <div className="mt-1 w-full gap-2  flex items-center">
                          <p className="">{t("search.currency")}</p>
                          <input
                            className="w-full outline-none"
                            type="number"
                            value={minPrice}
                            onChange={(e) => {
                              // if (Number(e.target.value) > 0) {
                              //   return
                              // }
                              setMinPrice(
                                e.target.value === ""
                                  ? ""
                                  : Number(e.target.value)
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col items-start py-3.5 pr-6 pl-4 text-xs text-black bg-white rounded-md border border-gray-500 border-solid shadow-lg w-full">
                        <div>{t("search.maxPrice")}</div>
                        <div className="mt-1 w-full gap-2  flex items-center">
                          <p className="">{t("search.currency")}</p>
                          <input
                            className="w-full outline-none"
                            type="number"
                            value={maxPrice}
                            onChange={(e) =>
                              setMaxPrice(
                                e.target.value === ""
                                  ? ""
                                  : Number(e.target.value)
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        if (minPrice >= maxPrice) {
                          toastMessage(
                            "Max Price must be greater than Min Price",
                            "error"
                          );
                          return;
                        }
                        if (minPrice <= 0 || maxPrice <= 0) {
                          toastMessage(
                            "Min Price and Max Price cannot be less then 0",
                            "error"
                          );
                          return;
                        }
                        setPriceSearch(!priceSearch);
                      }}
                      className="w-full rounded flex items-center justify-center py-2 bg-[#7F778F] text-white poppins"
                    >
                      {t("search.Search")}
                    </button>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>
          <hr className="-mt-4" />
        </div>
        <div className="w-full flex flex-col gap-4">
          {cars.length > 0 ? (
            cars?.map((car: any) => {
              return (
                <div className="flex flex-col px-6 pt-6 border-b border-solid border-neutral-200 md:px-0">
                  <div className="flex-wrap content-center  pt-3.5 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                      <div className="flex flex-col items-center  md:items-start justify-center  max-md:ml-0 max-md:w-full">
                        <img
                          loading="lazy"
                          src={car?.images[0].url}
                          className="mt-2 rounded-md w-52 md:w-[22rem] md:mb-6 aspect-[1.41] max-md:mt-10"
                        />
                      </div>
                      <div className="flex flex-col ml-5 w-[71%] max-md:ml-0 max-md:w-full">
                        <div className="grow max-md:mt-10 max-md:max-w-full">
                          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col w-[63%] max-md:ml-0 max-md:w-full">
                              <div className="flex flex-col grow justify-center items-center md:py-20 font-medium text-zinc-800 max-md:max-w-full">
                                <div className="mt-1.5 text-base  sm:text-lg md:text-2xl md:ml-[20px]  uppercase max-md:max-w-full">
                                  {car?.carType +
                                    " " +
                                    car?.name +
                                    " " +
                                    car?.year}
                                </div>
                                {type === "DESTINATED" && (
                                  <div className="flex gap-1 py-px text-sm max-md:flex-wrap">
                                    <img
                                      loading="lazy"
                                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/703ef29f84f790e12cf6b186e83f90b7c05fea286273552bac598b61d848bccd?apiKey=8d89067347d641b99b038352d46b6e1d&"
                                      className="shrink-0 w-6 aspect-[1.09]"
                                    />
                                    <div className="max-md:max-w-full">
                                      {t("search.daysForFree")}{" "}
                                      {car?.freeDays || 0}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="flex flex-col  max-md:ml-0 max-md:w-full">
                              <div className="flex flex-col justify-center items-center tracking-widest md:tracking-normal md:items-end self-stretch md:pl-16 xl:pl-28 my-auto text-xs text-right text-black max-md:mt-10">
                                <div>{t("search.payAtBranch")}</div>
                                <div className="text-xl">
                                  {t("search.currency")} {car?.perDayPrice} /{" "}
                                  {t("search.day")}
                                </div>
                                <div>
                                  {t("search.total")} {t("search.currency")} &nbsp;
                                  {calculateDiscountedPrice(
                                    car?.perDayPrice,
                                    pickUpDate,
                                    dropOffDate,
                                    car?.freeDays
                                  )}
                                </div>
                                <div
                                  className="justify-center px-9 py-4 mt-4 mb-4 md:mb-0 text-base font-semibold text-white whitespace-nowrap bg-[#7F778F] rounded-md max-md:px-5 cursor-pointer"
                                  onClick={() => {
                                    dispatch(
                                      updateSelection({
                                        dropOffDate,
                                        pickUpDate,
                                        pickUpLocation,
                                        dropOffLocation,
                                        type,
                                        carId: String(car?.id),
                                      })
                                    );
                                    navigate("/checkout");
                                  }}
                                >
                                  {t("search.Select")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="w-full flex justify-center">
              <h6 className="font-semibold text-xl poppins text-[#333]">
                No Car is available on date{" "}
                {moment(pickUpDate).format("DD MMMM YYYY")} at {pickUpLocation}
              </h6>
            </div>
          )}
        </div>
        {totalPage>1 && (
 <div className="w-full flex items-center justify-center">
 <ReactPaginate
   onPageChange={({ selected }) => {
     console.log(selected);
     setPage(selected + 1);
   }}
   pageCount={totalPage}
   
   // pageRangeDisplayed={totalPage}
   nextLabel="Next >"
   previousLabel="< Back"
   renderOnZeroPageCount={null}
   containerClassName="flex justify-center items-center gap-2"
   previousClassName={`border border-[#E9E9E9] poppins rounded px-4 py-2 font-medium text-sm ${page===1 && "hidden"} `}
   nextClassName={`border border-[#E9E9E9] poppins rounded px-4 py-2 font-medium text-sm ${totalPage===page && "hidden"} `}
   pageClassName="rounded border border-[#E9E9E9] poppins text-sm font-medium px-4 py-2"
   activeClassName="rounded border border-[#7F778F] bg-[#7F778F] poppins text-white text-sm font-medium px-4 py-2"
 />
</div>
        )}
       
      </div>
    </>
  );
};

export default SearchPage;
