import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import Theme from "./utils/theme";
import { ThemeProvider } from "@mui/material/styles";

import i18n from "i18next";
import i18nextBrowserLanguagedetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import I18NextHttpBackend from "i18next-http-backend";
import { InitOptions } from "i18next";

const i18nOptions:InitOptions={
  lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: "en",
    // whitelist: ["en", "ar"],
    detection: {
      order: ["localStorage", "htmlTag", "path", "cookie", "subdomain"],
      caches: ["localStorage"],
      //checkWhitelist: true,/ =>excluding
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/lang/{{lng}}.json",
    },
}

i18n

  .use(i18nextBrowserLanguagedetector)
  .use(initReactI18next)
  .use(I18NextHttpBackend)
  .init(i18nOptions);




const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <App />
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
