import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "../../ui/dialog";
import { Button } from "../../ui/button";
import { WhatsApp } from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Fab = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = (val: boolean) => {
    setOpen(val);
  };
  const openWhatsApp = () => {
    window.open("https://wa.me/966501920304", "_blank");
  };
  return (
    <>
      <Dialog open={open}>
        <DialogTrigger
          onClick={() => {
            handleOpen(true);
          }}
        >
          <Button
            style={{
              position: "fixed",
              bottom: "10px",
              right: "10px",
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              padding: "20px",
              zIndex: 200,
            }}
            // onClick={}
          >
            <WhatsApp
              style={{ color: "25D366", height: "40px", width: "40px" }}
            />
          </Button>
        </DialogTrigger>
        <DialogContent onCloseAutoFocus={(e) => e.preventDefault()}>
        {t(`fab.confirmationQuestion`)} 
          <DialogFooter>
            <div style={{ display: "flex" }}>
              <Button
                type="submit"
                onClick={() => {
                  openWhatsApp();
                  handleOpen(false);
                }}
              >
                {t(`fab.yesButton`)} 
              </Button>
              <Button
                variant={"outline"}
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  handleOpen(false);
                }}
              >
               {t(`fab.noButton`)} 
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Fab;
