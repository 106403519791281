import React from 'react'
import Landing from './Landing'

import SliderHome from './SliderHome'

const Home = () => {
  return (
    <div className='w-full flex flex-col gap-8' >
        <Landing />
        <SliderHome />
    </div>
  )
}

export default Home