import React, { useState,useEffect } from "react";

import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";

import { Input } from "../../ui/input";
import { locations } from "../../constants/locations";
import { useNavigate } from "react-router";

import { useAppDispatch } from "../..//store/hooks";
import { updateSelection } from "../..//store/slices/selected";
import { toastMessage } from "../../utils/toast";
import moment from "moment";
import { useTranslation } from "react-i18next";

const formSchema = z.object({
  pickUpLocation: z.string().min(2, {
    message: "Please Fill this field",
  }),
  dropOffLocation: z.string().min(2, {
    message: "Please Fill this field",
  }),
  pickUpDate: z.string().min(2, {
    message: "Please Fill this field",
  }),
  dropOffDate: z.string().min(2, {
    message: "Please Fill this field",
  }),
});


const SearchForm = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [language, setLanguage] = useState(""  as any)
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      pickUpLocation: "",
      dropOffLocation: "",
      pickUpDate: "",
      dropOffDate: "",
    },
  });

useEffect(() => {
  const lang = localStorage.getItem('language');
  setLanguage(lang)



}, [localStorage.getItem('language')])



  function onSubmit(values: z.infer<typeof formSchema>) {
    console.log(values);

    if (step===1 &&  (values.pickUpLocation === values.dropOffLocation)) {
      toastMessage("Pick up and drop off location can't be same in destinated.", "error");
      return;
    }

    dispatch(
      updateSelection({
        pickUpLocation: values.pickUpLocation,
        dropOffLocation: values.dropOffLocation,
        pickUpDate: values.pickUpDate,
        dropOffDate: values.dropOffDate,
        carId: "",
        type: step === 1 ? "DESTINATED" : "UNDESTINATED",
      })
    );

    navigate(`/search`);
  }

  console.log(form.getValues("pickUpDate"));

  return (
    <div className="w-full flex flex-col ">
       <div className="w-fit flex items-center ">
        <div
          className={`flex cursor-pointer items-center text-sm font-medium  justify-center p-4 ${
            step === 2
              ? "text-white bg-[#7F778F] "
              : "text-[#AEADB5] bg-[#E7E7E7] "
          } `}
          style={{
            // borderRadius: "16px 0px 0px 0px",
            borderRadius: language === 'en' ? "16px 0px 0px 0px" : "0px 16px 0px 0px",
          }}
          onClick={() => setStep(1)}
        >
          {t(`searchForm.destinated`)} 
        </div>
        <div
          className={`flex cursor-pointer items-center text-sm font-medium  justify-center p-4 ${
            step === 1
              ? "text-white bg-[#7F778F] "
              : "text-[#AEADB5] bg-[#E7E7E7] "
          } `}
          style={{
            borderRadius: language==="en"?"0px 16px 0px 0px":"16px 0 0 0 ",
          }}
          onClick={() => setStep(2)}
        >
        {t(`searchForm.undestinated`)} 
        </div>
      </div>
      <Form {...form}>
        <form
          className="w-full  p-8 md:grid md:grid-cols-11 flex flex-col  gap-3 md:gap-6 "
          style={{
            
            borderRadius: language==="ar"?"16px 0 16px 16px ":"0px 16px 16px 16px",
            background: "rgba(255, 255, 255, 0.90)",
            backdropFilter: "blur(14px)",
            boxShadow:
              "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
          }}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="col-span-3 flex flex-col gap-2">
            <FormField
              control={form.control}
              name="pickUpLocation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel> {t(`searchForm.pickUpLocation`)} </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t(`searchForm.searchLocationPlaceholder`)} 
                          className=" placeholder:text-[#AEADB5] poppins "
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {locations.map((location: any) => {
                        return (
                          <SelectItem value={location}>
                            <div className="flex items-center gap-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                              >
                                <circle
                                  cx="10.0001"
                                  cy="10.5"
                                  r="3.33333"
                                  stroke="#4B465C"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <circle
                                  cx="10.0001"
                                  cy="10.5"
                                  r="3.33333"
                                  stroke="white"
                                  stroke-opacity="0.5"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <p className="poppins text-sm font-medium text-[#333333]">
                                {location}
                              </p>
                            </div>
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-3 flex flex-col gap-2">
            <FormField
              control={form.control}
              name="dropOffLocation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel> {t(`searchForm.dropOffLocation`)} </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t(`searchForm.searchLocationPlaceholder`)} 
                          className=" placeholder:text-[#AEADB5] poppins "
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {locations.map((location: any) => {
                        return (
                          <SelectItem value={location}>
                            <div className="flex items-center gap-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                              >
                                <circle
                                  cx="10.0001"
                                  cy="10.5"
                                  r="3.33333"
                                  stroke="#4B465C"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <circle
                                  cx="10.0001"
                                  cy="10.5"
                                  r="3.33333"
                                  stroke="white"
                                  stroke-opacity="0.5"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <p className="poppins text-sm font-medium text-[#333333]">
                                {location}
                              </p>
                            </div>
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-2 flex flex-col gap-2 ">
            <FormField
              control={form.control}
              name="pickUpDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel> {t(`searchForm.pickUpDate`)} </FormLabel>
                  <FormControl>
                    <Input
                      type="date"
                      min={moment().format("YYYY-MM-DD")}
                      placeholder="Pick up date"
                      className="w-full"
                      {...field}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-2 flex flex-col gap-2 ">
            <FormField
              control={form.control}
              name="dropOffDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel> {t(`searchForm.dropOffDate`)} </FormLabel>
                  <FormControl>
                    <Input
                      type="date"
                      placeholder="shadcn"
                      className="w-full"
                      {...field}
                      min={moment(form.getValues("pickUpDate"))
                        .add(1, "days")
                        .format("YYYY-MM-DD")}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="w-full flex items-end justify-center">
            <button type="submit" className="rounded-full bg-[#7F778F]  p-4">
              <img className="" src="/assets/icons/search.svg" alt="" />
            </button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default SearchForm;
