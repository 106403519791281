import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

const Footer = () => {
  const location = useLocation();

  const{t}= useTranslation();

  return (
    <footer className={`flex z-20 flex-col justify-center text-white bg-white ${location.pathname==="/" && "md:-mt-[16rem] mt-[50px]" }  `}>
      <div className="flex justify-center items-center px-16 py-14 w-full bg-black max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col w-full max-w-[1206px] max-md:max-w-full">
          <img src="/assets/images/logo.png" alt="Company logo" className="max-w-full aspect-[3.85] w-[210px]" />
          <hr className="shrink-0 mt-24 h-px bg-white border border-white border-solid max-md:mt-10 max-md:max-w-full" />
          <nav className="flex gap-5 justify-between self-start mt-4 text-sm font-medium">
            <a href="/privacy-policy">{t(`footer.privacyPolicy`)}</a>
            <a href="/terms-and-conditions">{t(`footer.termsAndConditions`)}</a>
          </nav>
          <div className="mt-3.5 text-xs max-md:max-w-full">
            &copy; {t(`footer.allRightsReserved`)}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;