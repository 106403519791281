import { TextField } from "@mui/material";
import React, { useState } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import {
  calculateDiscountedPrice,
  calculateTotalPrice,
} from "../../utils/utility";
import axios from "axios";
import { backendUrl } from "../../constants/backendUrl";
import { toastMessage } from "../../utils/toast";
import moment from "moment";

type FormProps = {
  reservationId: string;
};

const styles = {
  root: {
    "& .MuiInputBase-input:-webkit-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    "& .MuiInputBase-input:-moz-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    backgroundColor: "white",
    borderRadius: "8px",
    color: "#858D9D !important",
    "& .MuiInputLabel-outlined	": {
      color: "#333333",

      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 500,
    },
    "& .MuiInputBase-input": {
      color: "#333333",
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: 400,
    },
  },
};

const Reservation = () => {
  const [loading, setLoading] = useState(false);
  const [reservation, setReservation] = useState(null as any);
  const [reservationStatus, setReservationStatus] = useState("");
  const [reservationId, setreservationId] = useState("");
  const { t } = useTranslation();

  const onSubmit = async (values: FormProps) => {
    try {
      setLoading(true);

      const { data } = await axios.post(
        `${backendUrl}/app/reservation/checkReservation`,
        values
      );
      console.log(data);
      setReservation(data.data);
      setReservationStatus(data.status);
      toastMessage(data?.message, "success");
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.errors ||
          error?.response?.data?.message ||
          "Something went wrong in fetching you Reservation, Please try again.",
        "error"
      );
    }
  };

  const defaultValues = {
    reservationId: "",
  };

  return (
    <div className="md:px-32 px-2 md:py-16 py-4 flex flex-col gap-8">
      <h6 className="text-[#7F778F] text-3xl font-bold poppins ">
        {t(`reservation.reservation_status`)}
      </h6>
      <div className="w-full border border-[#DBDADE] rounded p-4 md:p-8 flex flex-col gap-4">
        <div className="w-full flex justify-between">
          <p className="text-[#333] mt-1 md:text-xl text-lg font-medium">
            {t(`reservation.reservation_id`)}{" "}
          </p>
          <div className="w-1/2">
            <FormContainer
              defaultValues={defaultValues}
              resetOptions={{
                keepValues: false,
              }}
              onSuccess={onSubmit}
            >
              <div className="flex w-full flex-col gap-8">
                <TextFieldElement
                  name="reservationId"
                  sx={styles.root}
                  variant="outlined"
                  required
                />
                <div className="w-full flex justify-end gap-4">
                  <button
                    type="submit"
                    className="text-white font-semibold poppins py-3 px-8 bg-[#7F778F] rounded"
                  >
                    {t(`reservation.search`)}
                  </button>
                  <div
                    onClick={() => {
                      document.location.reload();
                    }}
                    className="text-white cursor-pointer font-semibold poppins py-3 px-8 bg-[#AEADB5] rounded"
                  >
                    {t(`reservation.reset`)}
                  </div>
                </div>
              </div>
            </FormContainer>
          </div>
        </div>
        <div className="poppins text-xl font-medium text-[#333]">
          {t(`reservation.status`)} :{" "}
          <span
            className={`${
              reservationStatus === "CONFIRMED"
                ? "text-green-500"
                : "text-[#FDAE2A]"
            } `}
          >
            {reservationStatus}
          </span>
        </div>
      </div>
      {reservationStatus === "CONFIRMED" && (
        <div className="flex flex-col pt-8 bg-white rounded border border-solid border-zinc-300">
          <div className="self-center text-2xl font-medium text-zinc-800 max-md:max-w-full">
            {t(`reservation.reservation_information`)}
          </div>
          <div className="justify-between px-10 pt-5 pb-8 mt-3 w-full max-md:px-5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow text-base font-semibold text-zinc-800 max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-2.5 max-md:flex-wrap">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a4cb3cd36fbf91b4657d7736d1a6cf9c3c8a3389d88137ea83542519d2afcbbc?apiKey=8d89067347d641b99b038352d46b6e1d&"
                      className="shrink-0 w-6 aspect-square"
                    />
                    <div className="flex-1 max-md:max-w-full">
                      <span className="text-base leading-6 text-zinc-800">
                        {t(`reservation.pickup_location`)} :
                      </span>{" "}
                      <span className="text-base leading-6 font-normal text-zinc-800">
                        {reservation?.pickUpLocation}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-2.5 mt-4 max-md:flex-wrap">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/dc194e3eae8fb3e04d77d57120b8177a393122d1a5eb527431ed2622e158f102?apiKey=8d89067347d641b99b038352d46b6e1d&"
                      className="shrink-0 w-6 aspect-square"
                    />
                    <div className="flex-1 max-md:max-w-full">
                      <span className="text-base leading-6 text-zinc-800">
                        {t(`reservation.pickup_date`)} :
                      </span>{" "}
                      <span className="text-base leading-6 font-normal text-zinc-800">
                        {moment(reservation?.fromDate).format("DD MMM,YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-2.5 mt-4 text-base font-medium max-md:flex-wrap">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1577c1b0e3d2cf55c5782ccfd0900c218054f533e441aef2dd4650d2197b79bf?apiKey=8d89067347d641b99b038352d46b6e1d&"
                      className="shrink-0 w-6 aspect-square"
                    />
                    <div className="flex-1 max-md:max-w-full">
                      <span className="font-semibold">
                        {t(`reservation.model_year`)} :
                      </span>{" "}
                      <span className="font-normal">
                        {reservation?.car?.year}
                      </span>
                    </div>
                  </div>
                  {reservation?.reservationType === "DESTINATED" && (
                    <div className="flex gap-2.5 mt-4 text-base max-md:flex-wrap">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dadcff666bbbe46652ee343c8c868870ef3409ad5a36e93b32ae53c5ccdf617?apiKey=8d89067347d641b99b038352d46b6e1d&"
                        className="shrink-0 w-6 aspect-square"
                      />
                      <div className="flex-1 max-md:max-w-full">
                        {t(`reservation.no_of_free_days`)} :{" "}
                        <span className="font-normal">
                          {reservation?.car?.freeDays || 0}
                        </span>
                      </div>
                      .
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col text-base font-semibold text-zinc-800 max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-2.5 max-md:flex-wrap">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/9fedfc68f9d80762cc35a68873037c923de0b890ca080dbd07859ef3fe9b5bb5?apiKey=8d89067347d641b99b038352d46b6e1d&"
                      className="shrink-0 w-6 aspect-square"
                    />
                    <div className="flex-1 max-md:max-w-full">
                      <span className="text-base leading-6 text-zinc-800">
                        {t(`reservation.return_location`)} :
                      </span>{" "}
                      <span className="text-base font-normal leading-6 text-zinc-800">
                        {reservation?.dropOffLocation}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-2.5 mt-4 max-md:flex-wrap">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ed3295772299c0cabbab58d9c163db15213cf31671c45499883227ef58ca5913?apiKey=8d89067347d641b99b038352d46b6e1d&"
                      className="shrink-0 w-6 aspect-square"
                    />
                    <div className="flex-1 max-md:max-w-full">
                      <span className="text-base leading-6 text-zinc-800">
                        {t(`reservation.return_date`)} :
                      </span>{" "}
                      <span className="text-base font-normal leading-6 text-zinc-800">
                        {moment(reservation?.toDate).format("DD MMM,YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {reservationStatus === "CONFIRMED" && (
        <div className="flex flex-col pt-8 bg-white rounded border border-solid border-zinc-300">
          <div className="self-center text-2xl font-medium text-zinc-800 max-md:max-w-full">
            {" "}
            {t(`reservation.company_information`)}{" "}
          </div>
          <div className="justify-between px-10 pt-5 pb-8 mt-3 w-full max-md:px-5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col text-base font-semibold text-zinc-800 max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-2.5 max-md:flex-wrap">
                    {" "}
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/05ec11be95d8efa823d79c84628ccf0208f84bcef023a525c2fc8ce52e650560?apiKey=8d89067347d641b99b038352d46b6e1d&"
                      className="shrink-0 w-6 aspect-square"
                    />
                    <div className="flex-1 max-md:max-w-full">
                      {" "}
                      <span className="text-base leading-6 text-zinc-800">
                        {t(`reservation.company_name`)} :{" "}
                      </span>{" "}
                      <span className="text-base font-normal leading-6 text-zinc-800">
                        {" "}
                        {reservation?.car?.createdBy?.company?.name}
                      </span>{" "}
                    </div>
                  </div>
                  <div className="flex gap-2.5 mt-4 leading-6 max-md:flex-wrap">
                    {" "}
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a4cb3cd36fbf91b4657d7736d1a6cf9c3c8a3389d88137ea83542519d2afcbbc?apiKey=8d89067347d641b99b038352d46b6e1d&"
                      className="shrink-0 my-auto w-6 aspect-square"
                    />
                    <div className="flex-1 max-md:max-w-full">
                      {" "}
                      <span className="text-base leading-6 text-zinc-800">
                        {t(`reservation.head_branch_address`)} :{" "}
                      </span>{" "}
                      <span className="text-base  font-normal leading-6 text-zinc-800">
                        {reservation?.car?.createdBy?.company?.branchAddress}
                      </span>{" "}
                    </div>
                  </div>
                  <div className="flex gap-2.5 mt-4 text-base font-medium leading-6 max-md:flex-wrap">
                    {" "}
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/49a566bca6b3f5d899655d95a68c4d21dd0e577300828fb44d7128b7129db6d2?apiKey=8d89067347d641b99b038352d46b6e1d&"
                      className="shrink-0 w-6 aspect-square"
                    />
                    <div className="flex-1 max-md:max-w-full">
                      {" "}
                      <span className="font-semibold">
                        {" "}
                        {t(`reservation.website`)} :
                      </span>{" "}
                      <span className="font-normal">
                        {reservation?.car?.createdBy?.company?.website}{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow text-base font-semibold text-zinc-800 max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-2.5 max-md:flex-wrap">
                    {" "}
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/7c2b21f08d0dcd369c2a07729132345f71ac65a432653d8c21e94d159b46346a?apiKey=8d89067347d641b99b038352d46b6e1d&"
                      className="shrink-0 w-6 aspect-square"
                    />
                    <div className="flex-1 max-md:max-w-full">
                      {" "}
                      <span className="text-base leading-6 text-zinc-800">
                        {t(`reservation.phone_number`)} :{" "}
                      </span>{" "}
                      <span className="text-base font-normal leading-6 text-zinc-800">
                        {" "}
                        {reservation?.car?.createdBy?.company?.phone}
                      </span>{" "}
                    </div>
                  </div>
                  <div className="flex gap-2.5 mt-4 max-md:flex-wrap">
                    {" "}
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/7c2b21f08d0dcd369c2a07729132345f71ac65a432653d8c21e94d159b46346a?apiKey=8d89067347d641b99b038352d46b6e1d&"
                      className="shrink-0 w-6 aspect-square"
                    />
                    <div className="flex-1 max-md:max-w-full">
                      {" "}
                      <span className="text-base leading-6 text-zinc-800">
                        {t(`reservation.whatsapp_business_number`)} :{" "}
                      </span>{" "}
                      <span className="text-base font-normal leading-6 text-zinc-800">
                        {" "}
                        {reservation?.car?.createdBy?.company?.whatsapp}
                      </span>{" "}
                    </div>
                  </div>
                  <div className="flex gap-2.5 mt-4 leading-6 max-md:flex-wrap">
                    {" "}
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/0c2415137068c5050074f79ec2393fb34a129c5c04d437a59224cecb0042045b?apiKey=8d89067347d641b99b038352d46b6e1d&"
                      className="shrink-0 my-auto w-6 aspect-square"
                    />
                    <div className="flex-1 max-md:max-w-full">
                      {" "}
                      <span className="text-base leading-6 text-zinc-800">
                        {t(`reservation.checklist`)} :{" "}
                      </span>{" "}
                      <span className="text-base font-normal leading-6 text-zinc-800">
                        {reservation?.car?.createdBy?.company?.companyCheckList
                          ?.map((checklist:any) => checklist.title)
                          .join(", ")}
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Reservation;
