export const calculateTotalPrice = (
  pricePerDay: any,
  pickUpDate: any,
  dropOffDate: any
) => {
  const from: any = pickUpDate;
  const to: any = dropOffDate;

  const fromDate = new Date(from);
  const toDate = new Date(to);

  const days = (toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24) + 1;
  console.log(days, pickUpDate, dropOffDate);

  const totalPrice = days * pricePerDay;
  return totalPrice.toFixed(2);
};

export const calculateDiscountedPrice = (
  pricePerDay: any,
  pickUpDate: any,
  dropOffDate: any,
  freeDays = 0
) => {
  const totalPrice = Number(
    calculateTotalPrice(pricePerDay, pickUpDate, dropOffDate)
  );

  const totalFreeDaysPrice = Number(freeDays) * Number(pricePerDay);
  const discountedPrice = totalPrice - totalFreeDaysPrice;
  return discountedPrice > 0 ? discountedPrice.toFixed(2) : 0;
};
