import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

let pickUpLocation;
let dropOffLocation;
let pickUpDate;
let dropOffDate;
let carId;
let type;

if (typeof window !== "undefined") {
  // Perform localStorage action
  pickUpLocation = localStorage.getItem("pickUpLocation")
    ? JSON.parse(localStorage.getItem("pickUpLocation") || "")
    : null;
  dropOffLocation = localStorage.getItem("dropOffLocation")
    ? JSON.parse(localStorage.getItem("dropOffLocation") || "")
    : null;
  pickUpDate = localStorage.getItem("pickUpDate")
    ? JSON.parse(localStorage.getItem("pickUpDate") || "")
    : null;
  dropOffDate = localStorage.getItem("dropOffDate")
    ? JSON.parse(localStorage.getItem("dropOffDate") || "")
    : null;
  carId = localStorage.getItem("carId")
    ? JSON.parse(localStorage.getItem("carId") || "")
    : null;
  type = localStorage.getItem("type")
    ? JSON.parse(localStorage.getItem("type") || "")
    : null;
}

interface initialStateType {
  pickUpLocation: string;
  dropOffLocation: string;
  pickUpDate: string;
  dropOffDate: string;
  carId: string;
  type: string;
}

const initialState: initialStateType = {
  pickUpLocation,
  dropOffLocation,
  pickUpDate,
  dropOffDate,
  carId,
  type,
};

const selectedSlice = createSlice({
  name: "selected",
  initialState,
  reducers: {
    updateSelection: (state, action: PayloadAction<any>) => {
      state.pickUpDate = action.payload.pickUpDate;
      state.dropOffDate = action.payload.dropOffDate;
      state.pickUpLocation = action.payload.pickUpLocation;
      state.dropOffLocation = action.payload.dropOffLocation;
      state.carId = action.payload.carId;
      state.type = action.payload.type;
      localStorage.setItem("pickUpDate", JSON.stringify(state.pickUpDate));
      localStorage.setItem("dropOffDate", JSON.stringify(state.dropOffDate));
      localStorage.setItem(
        "pickUpLocation",
        JSON.stringify(state.pickUpLocation)
      );
      localStorage.setItem(
        "dropOffLocation",
        JSON.stringify(state.dropOffLocation)
      );
      localStorage.setItem("carId", JSON.stringify(state.carId));
      localStorage.setItem("type", JSON.stringify(state.type));
    },
  },
});

export const { updateSelection } = selectedSlice.actions;

export default selectedSlice.reducer;
