import Loading from "react-fullscreen-loading";

import React from 'react'

const Loader = ({ loading }) => {
  return (
    <Loading loading={loading} background="#fff" loaderColor="#7F778F" />
  )
}

export default Loader