import "./App.css";
import { Routes, Route } from "react-router-dom";

import Header from "./components/Layout/Header";
import Home from "./components/Home/Home";
import Footer from "./components/Layout/Footer";
import SearchPage from "./components/SearchPage/SearchPage";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Tnc from "./components/TnC/Tnc";

import { Toaster } from "react-hot-toast";
import Checkout from "./components/Checkout/Checkout";
import Successfull from "./components/Checkout/Successfull";
import Reservation from "./components/Reservation/Reservation";
import Fab from "./components/Fab/Fab";
import { useTranslation } from "react-i18next";



function App() {
  const {i18n} = useTranslation();
  return (
    <div className="w-full poppins flex flex-col" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      <Fab />
      <Toaster />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/successfull-reservation" element={<Successfull />} />
        <Route path="/reservation-status" element={<Reservation />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<Tnc />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
