import React, { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import axios from "axios";
import Loader from "../../utils/Loader";
import { backendUrl } from "../../constants/backendUrl";
import { toastMessage } from "../..//utils/toast";
import { useNavigate } from "react-router-dom";
import {
  calculateDiscountedPrice,
  calculateTotalPrice,
} from "../../utils/utility";

import {
  FormContainer,
  TextFieldElement,
  AutocompleteElement,
  SelectElement,
} from "react-hook-form-mui";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../ui/carousel";
import { Checkbox } from "@mui/material";
import moment from "moment";
import { useWatch } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

type FormProps = {
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  phone: string;
  whatsapp: string;
};

const styles = {
  root: {
    "& .MuiInputBase-input:-webkit-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    "& .MuiInputBase-input:-moz-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    backgroundColor: "white",
    borderRadius: "8px",
    color: "#858D9D !important",
    "& .MuiInputLabel-outlined	": {
      color: "#333333",

      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 500,
    },
    "& .MuiInputBase-input": {
      color: "#333333",
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: 400,
    },
  },
};

const Checkout = () => {
  const {
    pickUpDate,
    pickUpLocation,
    dropOffLocation,
    dropOffDate,
    carId,
    type,
  } = useAppSelector((state) => state.selected);
  const [loading, setLoading] = useState(false);
  const [car, setCar] = useState(null as any);
  const navigate = useNavigate();
  const [phone, setPhone] = useState(null as any);
  const [isSame, setIsSame] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [acceptUpdates, setAcceptUpdates] = useState(false);
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
  });

  const getCar = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${backendUrl}/app/car/getCarById/${carId}`
      );
      console.log(data.data);
      setCar(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage("Something went wrong in fetching car details", "error");
    }
  };

  useEffect(() => {
    getCar();
  }, [carId]);

  const defaultValues = {
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    phone: "",
    whatsapp: "",
  };

  const onSubmit = async (values: FormProps) => {
    if (!acceptPrivacy) {
      toastMessage("Please accept our privacy policy", "error");
      return;
    }
    if (!acceptUpdates) {
      toastMessage("Please accept our consent for whatsapp updates.", "error");
      return;
    }
    if (isSame) {
      values.whatsapp = phone;
    }
    console.log(values);

    try {
      setLoading(true);

      const payload = {
        ...values,
        fromDate: moment(pickUpDate).toISOString(),
        toDate: moment(dropOffDate).toISOString(),
        pickUpLocation: pickUpLocation,
        dropOffLocation: dropOffLocation,
        carId: Number(carId),
        dob: moment(values.dob).toISOString(),
        phone: String(values.phone),
        whatsapp: String(values.whatsapp),
        reservationType: type,
        totalPrice: Number(
          calculateDiscountedPrice(
            car?.perDayPrice,
            pickUpDate,
            dropOffDate,
            car?.freeDays
          )
        ),
      };

      const { data } = await axios.post(
        `${backendUrl}/app/reservation/${type==="DESTINATED"?"createDestinatedReservation":"createReservation"}`,
        payload
      );
      console.log(data);
      // toastMessage(
      //   "Reservation created successfully,Please wait for approval",
      //   "success"
      // );
      setLoading(false);
      navigate("/successfull-reservation");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        "Something went wrong in your Reservation, Please try again.",
        "error"
      );
    }
  };
 
  return (
    <>
      {" "}
      <Loader loading={loading} />
      <div className="w-full flex flex-col gap-8 md:px-24 py-8">
        <div className="w-full grid md:grid-cols-11 grid-rows gap-8">
          <div
            onClick={() => {
              navigate("/");
            }}
            className="cursor-pointer flex col-span-5 flex-col p-6 text-base font-semibold bg-white rounded-md shadow-lg w-full text-zinc-800"
          >
            <div className="flex gap-3 items-center">
              <div className="justify-center flex items-center self-stretch p-2.5 w-8 h-8 whitespace-nowrap rounded bg-stone-300">
                1
              </div>
              <div className="flex-1 self-stretch my-auto">
                {t(`checkout.Rental Location`)}
              </div>
              <img
                loading="lazy"
                src="/assets/icons/edit.svg"
                className="shrink-0 self-stretch my-auto aspect-square w-[25px]"
              />
            </div>
            <div className="flex gap-1.5 mt-2.5">
              <div className="flex-1">{t(`checkout.pick up`)}</div>
              <div className="flex-1">{t(`checkout.return`)}</div>
            </div>
            <div className="flex gap-1.5 text-sm font-medium">
              <div className="flex-1">{pickUpLocation}</div>
              <div className="flex-1">{dropOffLocation}</div>
            </div>
            <div className="flex gap-1.5 text-sm font-medium">
              <div className="flex-1">{pickUpDate}</div>
              <div className="flex-1">{dropOffDate}</div>
            </div>
          </div>
          <div
            onClick={() => {
              navigate("/search");
            }}
            className=" cursor-pointer flex col-span-5 md:col-span-3 flex-col p-6 text-sm font-medium bg-white rounded-md shadow-lg w-full text-zinc-800"
          >
            <div className="flex gap-3 items-center text-base font-semibold">
              <div className="justify-center flex items-center self-stretch p-2.5 w-8 h-8 whitespace-nowrap rounded bg-stone-300">
                2
              </div>
              <div className="flex-1 self-stretch my-auto">
                {t(`checkout.Select a Vehicle`)}
              </div>
              <img
                loading="lazy"
                src="/assets/icons/edit.svg"
                className="shrink-0 self-stretch my-auto aspect-square w-[25px]"
              />
            </div>
            <div className="mt-2.5">{car?.name}</div>
            <div className="mt-1  flex items-center">
              <div className="border border-solid border-[#7F778F] rounded-md p-2 inline-block">
                {t(`checkout.currency`)} {car?.perDayPrice.toFixed(2)+"/"+ t("search.day")}
              </div>
            </div>
          </div>
          <div className="flex  col-span-5 md:col-span-3 flex-col p-6 text-sm border border-gray-500 border-solid  font-medium bg-white rounded-md   shadow-lg w-full text-zinc-800">
            <div className="flex gap-3 items-center text-base font-semibold">
              <div className="justify-center flex items-center self-stretch p-2.5 w-8 h-8 whitespace-nowrap rounded bg-stone-300">
                3
              </div>
              <div className="flex-1 self-stretch my-auto">
                {t(`checkout.Review & Submit`)}
              </div>
            </div>
            <div className="mt-2.5 font-bold ">{t(`checkout.Total Price`)}</div>
            <div>
              {t(`checkout.currency`)}{" "}
              {calculateTotalPrice(car?.perDayPrice, pickUpDate, dropOffDate)}
            </div>
          </div>
        </div>
        <FormContainer
          defaultValues={defaultValues}
          onSuccess={onSubmit}
          values={{
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            dob: formValues.dob,
            email: formValues.email,
            phone: phone,
            whatsapp: isSame ? phone : "",
          }}
        >
          <div className="w-full grid md:grid-cols-12 grid-cols gap-4">
            <div className="w-full col-span-8 flex flex-col gap-4 ">
              <div className="w-full flex flex-col  border rounded-lg border-[#AEADB5]  ">
                <div className="w-full grid grid-cols-12 p-6 ">
                  <div className="col-span-5 w-full flex justify-center items-center">
                    <Carousel>
                      <CarouselContent className="">
                        {car &&
                          car?.images.map((image: any) => (
                            <CarouselItem>
                              <img
                                src={image?.url}
                                className="w-full rounded-md"
                              />
                            </CarouselItem>
                          ))}
                      </CarouselContent>
                      {car?.images.length > 1 && (
                        <>
                          <CarouselPrevious className="-left-6" />
                          <CarouselNext className="-right-6" />
                        </>
                      )}
                    </Carousel>
                  </div>
                  <div className="col-span-1"></div>
                  <div className="w-full flex justify-start items-center col-span-5">
                    <p className="text-[#333333] poppins font-medium uppercase">
                      {car?.name + " " + car?.carType + " " + car?.year}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-center self-stretch px-4 py-2 text-xs bg-white rounded-lg border-t border-[#AEADB5]  w-full text-zinc-400">
                  <div className="flex gap-5 justify-around pr-1.5 max-md:flex-wrap max-md:max-w-full">
                    <div className="flex flex-col gap-2 justify-center">
                      <p className="text-[#AEADB5] poppins text-xs">
                        {t(`checkout.pick up`)}
                      </p>
                      <p className="text-sm font-medium text-zinc-800">
                        {pickUpLocation}
                      </p>
                      <p>{moment(pickUpDate).format("YYYY-MM-DD")}</p>
                    </div>
                    <img
                      loading="lazy"
                      src="/assets/icons/arrow-right.svg"
                      alt=""
                      className="shrink-0 my-auto w-5 aspect-[1.43] fill-black"
                    />
                    <div className="flex flex-col gap-2 justify-center">
                      <p className="text-[#AEADB5] poppins text-xs">
                        {t(`checkout.return`)}
                      </p>
                      <p className="text-sm font-medium text-zinc-800">
                        {dropOffLocation}
                      </p>
                      <p>{moment(dropOffDate).format("YYYY-MM-DD")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-8 border rounded-lg border-[#AEADB5]  p-4">
                <h6 className="text-[#333333] poppinst text-lg ">
                  {t(`checkout.Driver Details`)}
                </h6>
                <div className="w-full flex flex-col gap-4">
                  <div className="w-full grid grid-cols-2 gap-4">
                    <TextFieldElement
                      name="firstName"
                      label={t(`checkout.First Name`)}
                      required
                      fullWidth
                      size="small"
                      sx={styles.root}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          firstName: e.target.value,
                        });
                      }}
                    />
                    <TextFieldElement
                      name="lastName"
                      label={t(`checkout.Last Name`)}
                      required
                      fullWidth
                      size="small"
                      sx={styles.root}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          lastName: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <TextFieldElement
                    name="dob"
                    label={t(`checkout.Date of Birth`)}
                    required
                    fullWidth
                    size="small"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    sx={styles.root}
                    onChange={(e) => {
                      setFormValues({ ...formValues, dob: e.target.value });
                    }}
                    inputProps={{
                      min: "1900-01-01",
                      max: moment().format("YYYY-MM-DD"),
                    }}
                  />
                  <TextFieldElement
                    name="email"
                    label={t(`checkout.Email Address`)}
                    required
                    fullWidth
                    type="email"
                    size="small"
                    sx={styles.root}
                    onChange={(e) => {
                      setFormValues({ ...formValues, email: e.target.value });
                    }}
                  />
                  <TextFieldElement
                    name="phone"
                    label={t(`checkout.Phone Number`)}
                    required
                    fullWidth
                    type="number"
                    size="small"
                    sx={styles.root}
                    value={phone}
                    onChange={(e: any) => setPhone(e.target.value)}
                  />
                  <div className="w-full flex gap-2 items-center">
                    <Checkbox
                      color="secondary"
                      checked={isSame}
                      onChange={(e) => {
                        setIsSame(e.target.checked);
                      }}
                    />
                    <p className="text-[#AEADB5] poppins text-sm leading-4">
                      {t(`checkout.Same as phone number`)}
                    </p>
                  </div>
                  <TextFieldElement
                    name="whatsapp"
                    label={t(`checkout.Whatsapp Number`)}
                    required
                    fullWidth
                    type="number"
                    size="small"
                    sx={styles.root}
                    disabled={isSame}
                    value={isSame ? Number(phone) : ""}
                    // defaultValue={isSame ? Number(phone) : ""}
                    // inputProps={{
                    //   value: isSame && isSame ? Number(phone) : "",
                    // }}
                    // InputLabelProps={{ shrink: isSame }}
                  />
                  <div className="w-full flex gap-2 items-center">
                    <Checkbox
                      color="secondary"
                      checked={acceptPrivacy}
                      onChange={(e) => {
                        setAcceptPrivacy(e.target.checked);
                      }}
                    />
                    <p className="text-[#AEADB5] poppins text-sm leading-4">
                      {t(
                        `checkout.I consent to privacy policy, terms & conditions`
                      )}
                    </p>
                  </div>
                  <div className="w-full flex gap-2 items-center">
                    <Checkbox
                      color="secondary"
                      onChange={(e) => {
                        setAcceptUpdates(e.target.checked);
                      }}
                      checked={acceptUpdates}
                    />
                    <p className="text-[#AEADB5] poppins text-sm leading-4">
                      {t(
                        `checkout.I consent to recieve updates on my whatsapp contact and email`
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full col-span-8 md:col-span-4 flex flex-col gap-4 ">
              {type === "DESTINATED" && (
                <div className="flex flex-col gap-6 border rounded-lg border-[#AEADB5]  p-4">
                  <div className="w-full flex justify-between items-center">
                    <p className="poppins text-[#333333] font-semibold">
                      {car?.freeDays || 0}
                    </p>
                    <img
                      src="/assets/icons/discount.svg"
                      alt="Discount Icon"
                      className=""
                    />
                  </div>
                  <p className="text-[#333] opacity-70 text-sm uppercase">
                    {t(`checkout.Free Days`)}
                  </p>
                </div>
              )}

              <div className="flex flex-col gap-6 border rounded-lg border-[#AEADB5]  p-4">
                <p className="poppins text-[#333333] font-semibold">
                  {t(`checkout.Price Details`)}
                </p>
                {type === "DESTINATED" && (
                  <div className="w-full flex items-center justify-between">
                    <p className="text-[#333] poppins">
                      {t(`checkout.Total Price`)}
                    </p>
                    <p className="text-[#333] poppins line-through">
                      {t(`checkout.currency`)}{" "}
                      {calculateTotalPrice(
                        car?.perDayPrice,
                        pickUpDate,
                        dropOffDate
                      )}
                    </p>
                  </div>
                )}

                {type === "DESTINATED" && (
                  <div className="w-full flex items-center justify-between">
                    <p className="text-[#7F778F] font-medium text-sm poppins">
                      {t(`checkout.new_price`)} ({t(`checkout.after`)}{" "}
                      {car?.freeDays} {t(`checkout.days_free`)} )
                    </p>
                    <p className="text-[#7F778F] poppins ">
                      {t(`checkout.currency`)}{" "}
                      {calculateDiscountedPrice(
                        car?.perDayPrice,
                        pickUpDate,
                        dropOffDate,
                        car?.freeDays
                      )}
                    </p>
                  </div>
                )}

                <hr />
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#333] text-sm poppins">
                    {t(`checkout.Total Price`)}
                  </p>
                  <p className="text-[#333] text-sm poppins ">
                    {t(`checkout.currency`)}{" "}
                    {calculateDiscountedPrice(
                      car?.perDayPrice,
                      pickUpDate,
                      dropOffDate,
                      car?.freeDays
                    )}
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-center">
              <button
                type="submit"
                className="md:w-1/2  w-1/3 py-3  bg-[#7F778F] text-white rounded poppins font-semibold"
              >
                {t(`checkout.Review & Submit`)}{" "}
              </button>
              </div>
            </div>
            
          </div>
        </FormContainer>
      </div>
    </>
  );
};

export default Checkout;
