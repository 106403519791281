import React from 'react'
import SearchForm from './SearchForm'

const Landing = () => {
  return (
    <div className='w-full relative h-[40vh] md:h-[65vh] ' >
        <img src="/assets/images/landing.png" alt="" className="absolute w-full h-full object-cover top-0" 
 />
        <div className="absolute md:-bottom-20 mt-[247px] md:mt-0 md:px-24  w-full ">
           <SearchForm />
        </div>
    </div>
  )
}

export default Landing