import React from "react";

const Successfull = () => {
  return (
    <div className="w-full px-4 md:px-24 flex md:py-20 py-4 flex-col gap-12 items-center justify-center">
      <img src="/assets/images/successfull.png" alt="" className="" />
      <div className="w-full flex flex-col gap-4">
        <h4 className="text-[#7F778F] font-bold text-center  text-xl md:text-3xl poppins">
          Congratulations, You have successfully put a reservation request
        </h4>
        <h6 className="text-[#333]  text-lg text-center md:text-xl poppins">
          All the updates will be provided on your Whatsapp and Email. Further,
          you can check status on "Reservation status".
        </h6>
      </div>
    </div>
  );
};

export default Successfull;
